import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from 'layouts'
import { Section, Feature, H1 } from 'components/ui'
import useMode from 'utils/useMode'
import ReactMarkdown from 'react-markdown'
import { Box, Text } from 'rebass'
import { Helmet } from 'react-helmet'

const FAQ = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { templateKey: { eq: "faq-page" } }) {
        id
        frontmatter {
          metaTitle
          metaDescription
          keywords
          articles {
            title
            description
          }
        }
      }
    }
  `)
  const [, setMode] = useMode()
  setMode('dark')
  return (
    <>
      <Layout>
        {/* <Helmet title="FAQ" /> */}
        {/* <Helmet>
          <title>Auto Import bedrijf dat gevestigd is in Amsterdam</title>
          <meta name="description" content="Auto Import bedrijf gevestigd  in Amsterdam  biedt haar klanten een  uitstekende services aan voor transport en aankoopafwikkelingen voor een scherpe prijs."></meta>
          <link rel="canonical" href="/faq" />
        </Helmet> */}
        <Helmet
          title="Auto Import bedrijf dat gevestigd is in Amsterdam"
          meta={[
            { name: 'description', content: 'Auto Import bedrijf gevestigd  in Amsterdam  biedt haar klanten een  uitstekende services aan voor transport en aankoopafwikkelingen voor een scherpe prijs.' },
            { property: 'og:description', content: 'Auto Import bedrijf gevestigd  in Amsterdam  biedt haar klanten een  uitstekende services aan voor transport en aankoopafwikkelingen voor een scherpe prijs.' },
          ]}
        />
        <Section>
          <h1 className="css-3vqtf1">Auto import bedrijf gevestigd in Amsterdam</h1>
          {data.markdownRemark.frontmatter.articles.map(
            (article: any, i: number) => (
              <Box key={article.id} my={8}>
                <Feature
                  index={i}
                  color="text"
                  title={article.title}
                  description={<ReactMarkdown source={article.description} />}
                />
              </Box>
            )
          )}
        </Section>
      </Layout>
    </>
  )
}

export default FAQ
